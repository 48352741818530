import { Observable } from "rxjs";
import { HttpClient } from "../HttpClient/HttpClient";
import { BaseObject } from "../BaseObject/BaseObject";
import { PagePartGroup } from "./PagePartGroup";
import { ConferenceState } from "../Enum/ConferenceState";
import { Participant } from "./Participant";
import { Feedback } from "./Feedback";
import { FreeTextType } from "../Enum/FreeTextType";
import { PagePart } from "./PagePart";


export class Conference extends BaseObject {

    public Title: string;
    public SubTitle: string;
    public ListOfParticipantsUrl: string;
    public PagePartGroups: Array<PagePartGroup>;
    public ConferenceState: ConferenceState;
    public Participant: Participant;
    public Feedbacks: Array<Feedback>;
    public UserPollingInterval: number;
    public AdminPollingInterval: number;

    public BeginDate: string;
    public EndDate: string;
    public VotingFrom: string;
    public VotingTo: string;
    public MinutesFrom: string;
    public MinutesTo: string;

    public ServerTime: string;

    public static GetForParticipantUid(uid: string): Observable<Conference> {
        let locHttpClient = new HttpClient();
        const ret = locHttpClient.GetObject<Conference>('conference/uid/' + uid);
        return ret;
    }

    public static CanBeFinalized(conference: Conference): boolean {
        let retVal = true;
        // find all questions
        conference.PagePartGroups.forEach((ppg) => {
            // nur die pagepartgroups die nicht finalize und acceptminutes sind
            if (this.HasVotingRightsPagePartGroup(conference, ppg) && ppg.SortOrder < 100) {
                ppg.PageParts.forEach((pp) => {
                    // check if participant has right for this
                    if (this.HasVotingRightsPagePart(conference, pp) && pp.Question) {
                    // if (pp.Question) {
                        // schaue ob es ein feedback gibt
                        if (pp.Question.FreeTextType !== FreeTextType.OnlyFreeText) {
                            const feedback = conference.Feedbacks.find((fb) => fb.QuestionId === pp.Question.Id);
                            // console.log(feedback);
                            if (!feedback) {
                                retVal = false;
                            }
                        }
                    }
                });
            }
        });
        return retVal;
    }

    public static IsFinalized(conference: Conference): boolean {
        return Conference.HasPagePartSortorder(conference, 999);
    }

    public static IsAcceptedMinutes(conference: Conference): boolean {
        return Conference.HasPagePartSortorder(conference, 1000);
    }

    public static HasPagePartSortorder(conference: Conference, PagePartSortOrder: number) {
        let retVal = true;
        conference.PagePartGroups.forEach((ppg) => {
            // nur die pagepartgroups die nicht finalize und acceptminutes sind
            if (ppg.SortOrder === 100) {
                ppg.PageParts.forEach((pp) => {
                    if (pp.SortOrder === PagePartSortOrder) {
                        const feedback = conference.Feedbacks.find((fb) => fb.QuestionId === pp.Question.Id);
                        if (!feedback) {
                            retVal = false;
                        }
                    }
                });
            }
        });
        return retVal;
    }

    public static HasVotingRightsPagePart(conference: Conference, pagepart: PagePart): boolean {
        return this.HasVotingRightsForSpecialTag(conference, pagepart.SpecialTag);
    }

    public static HasVotingRightsPagePartGroup(conference: Conference, pagepartgroup: PagePartGroup): boolean {
        return this.HasVotingRightsForSpecialTag(conference, pagepartgroup.SpecialTag);
    }

    public static HasVotingRightsForSpecialTag(conference: Conference, specialTag: string): boolean {
        const key = conference.Participant.Key;
        const term = specialTag;   // pagepart.SpecialTag.trim();
        let lHasVotingRight = true;
        if (term.trim().length > 0) {
            if (term.startsWith('NOT(')) {
                // string should be NOT(KEY1, KEY2, KEY3, ...)
                let exclusionTerm = term.substr(4, term.length - 5);
                let exclusionArray = exclusionTerm.split(',');
                let ind = exclusionArray.findIndex(exkey => exkey.trim() === key.trim());
                lHasVotingRight = ind === -1;
            }
        }
        return lHasVotingRight;
    }

    constructor() {
        super(0);
        this.Title = '';
        this.SubTitle = '';
        this.ListOfParticipantsUrl = '';
        this.PagePartGroups = new Array<PagePartGroup>();
        this.ConferenceState = ConferenceState.Loading;
        this.Participant = new Participant();
        this.Feedbacks = new Array<Feedback>();

        this.UserPollingInterval = 5;
        this.AdminPollingInterval = 5;

        this.BeginDate = '';
        this.EndDate = '';
        this.VotingFrom = '';
        this.VotingTo = '';
        this.MinutesFrom = '';
        this.MinutesTo = '';

        this.ServerTime = '';
    }

}
