import { Pad } from "./Pad";

export class DateTime {

    /* Props comes here */
    public year = 1753;
    public month = 1;
    public day = 1;
    public hour = 0;
    public minute = 0;
    public second = 0;

    constructor(isoDateTime?: string) {
        if (!isoDateTime) { return; }
        let parsedDate = null;

        if (isoDateTime.length === 10) {
            // Only date
            parsedDate = /(\d\d\d\d)-(\d\d)-(\d\d)/.exec(isoDateTime);
            if (parsedDate) {
                this.init(
                    Number(parsedDate[1]),
                    Number(parsedDate[2]),
                    Number(parsedDate[3]),
                    0,
                    0,
                    0);
            }
        } else if (isoDateTime.length === 19) {
            // Date & Time without milliseconds
            parsedDate = /(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d)/.exec(isoDateTime);
            if (parsedDate) {
                this.init(
                    Number(parsedDate[1]),
                    Number(parsedDate[2]),
                    Number(parsedDate[3]),
                    Number(parsedDate[4]),
                    Number(parsedDate[5]),
                    Number(parsedDate[6]));
            }
        } else if (isoDateTime.length === 23) {
            // Full Date & Time
            parsedDate = /(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d).(\d\d\d)/.exec(isoDateTime);
            if (parsedDate) {
                this.init(
                    Number(parsedDate[1]),
                    Number(parsedDate[2]),
                    Number(parsedDate[3]),
                    Number(parsedDate[4]),
                    Number(parsedDate[5]),
                    Number(parsedDate[6]));
            }
        } else if (isoDateTime.length > 23) {
            parsedDate = /(\d\d\d\d)-(\d\d)-(\d\d)T(\d\d):(\d\d):(\d\d).(\d\d\d)/.exec(isoDateTime.substr(0, 23));
            if (parsedDate) {
                this.init(
                    Number(parsedDate[1]),
                    Number(parsedDate[2]),
                    Number(parsedDate[3]),
                    Number(parsedDate[4]),
                    Number(parsedDate[5]),
                    Number(parsedDate[6]));
            }
        }
    }

    private init(year: number, month: number, day: number, hour: number, minute: number, second: number): void {
        this.year = year;
        this.month = month;
        this.day = day;
        this.hour = hour;
        this.minute = minute;
        this.second = second;
    }

    public getFormattedDate(): string {
        let DateString = '';
        DateString += Pad.Number(this.day, 2);
        DateString += '.';
        DateString += Pad.Number(this.month, 2);
        DateString += '.';
        DateString += Pad.Number(this.year, 4);
        return DateString;
    }

    public getFormattedTime(): string {
        let TimeString = '';
        TimeString += Pad.Number(this.hour, 2);
        TimeString += ':';
        TimeString += Pad.Number(this.minute, 2);
        TimeString += ':';
        TimeString += Pad.Number(this.second, 2);
        return TimeString;
    }

    public getFormattedDateTime(): string {
        return this.getFormattedDate() + ' ' + this.getFormattedTime();
    }

    public toDate(): Date {
        return new Date(this.year, this.month - 1, this.day, this.hour, this.minute, this.second);
    }

    public static DateFromISO(date: string): string {
        const newDate = new DateTime(date);
        let DateTimeString = '';
        DateTimeString += Pad.Number(newDate.day, 2);
        DateTimeString += '.';
        DateTimeString += Pad.Number(newDate.month, 2);
        DateTimeString += '.';
        DateTimeString += Pad.Number(newDate.year, 4);
        return DateTimeString;
    }

}
