// @ts-ignore
import { Observable, empty, of } from "rxjs";
import { ajax } from "rxjs/ajax";
import { map } from "rxjs/operators";
import { DataWrapper } from "./DataWrapper";
import { ObjectContent } from "./ObjectContentEnum";


export class HttpClient {

    // apilocation: string = "https://localhost:5001/";
    apilocation: string = "https://surconws.scholarships.at/";

    Post<T>(method: string, params: {}) {
        return ajax.post(this.apilocation + method, params,  { 'Content-Type': 'application/json; charset=utf-8' })
            .pipe(
                map(response => {
                    const dataWrapper = eval(response.response) as DataWrapper;
                    // console.log(dataWrapper);
                    switch (dataWrapper.ObjectContent) {
                        case ObjectContent.Primitive:
                            // console.log('Primitiv ' + dataWrapper.PrimitiveData)
                            return dataWrapper.PrimitiveData as T;
                            break;
                        case ObjectContent.Object:
                            return dataWrapper.ObjectData as T;
                            break;
                        default:
                            return dataWrapper.ObjectData as T;
                            break;
                    }
                })
            );
    }

    GetObject<T>(method: string) {
        return ajax.getJSON(this.apilocation + method)
            .pipe(
                map(response => {
                    const dataWrapper = response as DataWrapper;
                    switch (dataWrapper.ObjectContent) {
                        case ObjectContent.Primitive:
                            return dataWrapper.PrimitiveData as T;
                            break;
                        case ObjectContent.Object:
                            return dataWrapper.ObjectData as T;
                            break;
                        case ObjectContent.List:
                            return dataWrapper.ObjectList as T;
                            break;
                        default:
                            return dataWrapper.ObjectData as T;
                            break;
                    }
                })
            );
    }
}
