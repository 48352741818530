import React, { FC, useEffect, useState } from "react";
import { Question } from "../../model/conference/Question";
import AnswerButton from "./AnswerButton";
import { Alert, Button, Modal, Spinner } from "react-bootstrap";
import { useConference } from "../../context/ConferenceContext";
import { Feedback } from "../../model/conference/Feedback";
import { FreeTextType } from "../../model/Enum/FreeTextType";
import { useUid } from "../../context/UidContext";
import { Conference } from "../../model/conference/Conference";
import { useCState } from "../../context/StatusContext";
import { ConferenceState } from "../../model/Enum/ConferenceState";

type QuestionViewProps = {
    question: Question;
}

const QuestionView: FC<QuestionViewProps> = ( { question}) => {

    // Context
    const {conference, setConference} = useConference();
    const {uid} = useUid();
    const {cState} = useCState();

    // State
    const [isLoadingRemove, setIsLoadingRemove] = useState<boolean>(false);
    const [commentText, setCommentText] = useState<string>('');
    // const [commentTextCopy, setCommentTextCopy] = useState<string>('');
    const [answerText, setAnswerText] = useState<string>('');
    const [answerKey, setAnswerKey] = useState<string>('');
    const [feedback, setFeedback] = useState<Feedback>();
    const [showCannotFinalize, setShowCannotFinalize] = useState(false);

    const [canBeFinalized, setCanBeFinalized] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);
    const [isFinalizeButton, setIsFinalizeButton] = useState(false);

    // const [canBeAccepted, setCanBeAccepted] = useState(false);
    const [isAccepted, setIsAccepted] = useState(false);
    const [isAcceptedButton, setIsAcceptButton] = useState(false);

    const handleCannotFinalizeClose = () => setShowCannotFinalize(false);

    // Detect change of Conference
    useEffect(() => {
        setIsFinalized(Conference.IsFinalized(conference));
        setIsAccepted(Conference.IsAcceptedMinutes(conference));

        setCanBeFinalized(Conference.CanBeFinalized(conference));
        setFeedback(conference.Feedbacks.find(fb => fb.QuestionId === question.Id));

        // check if it is a SaveComment Answer
        const scAnswer = question.Answers.find(ans => ans.Key === 'SC');
        if (scAnswer) {
            setAnswerKey(scAnswer.Key);
        }

        if (feedback) {
            const answer = question.Answers.find(an => an.Id === feedback.AnswerId);
            if (answer) {
                setAnswerText(answer.Text);
                setAnswerKey(answer.Key);
                setCommentText(feedback.Comment);
                if (answer.Key === 'F') {
                    setIsFinalizeButton(true);
                }
                if (answer.Key === 'AM') {
                    setIsAcceptButton(true);
                }
            }
        } else {
            if (Conference.IsFinalized(conference)) {
                setCommentText('');
            }
        }
        // console.log(question.Id + ' reloaded');
    }, [conference, feedback, question]);

    const removeFeedback = () => {
        setIsLoadingRemove(true);
        const feedbacks = conference.Feedbacks;
        const index = feedbacks.findIndex(fb => fb.QuestionId === question.Id);
        if (index > -1) {
            Feedback.RemoveFeedback(feedbacks[index])
                .subscribe(resp => {
                    // console.log(resp);
                    if (resp) {
                        setFeedback(undefined);
                        setAnswerText('');
                        feedbacks.splice(index, 1);
                        const conf = {...conference, Feedbacks: feedbacks};
                        setConference(conf);
                    }
                    setIsLoadingRemove(false);
                });
        }
    }

    const saveFeedback = (e: React.MouseEvent, answerId: number) => {
        const answer = question.Answers.find(an => an.Id === answerId);
        if (!answer) { return; }
        if (answer.Key === 'F' && !canBeFinalized) {
            setShowCannotFinalize(true);
            return;
        }
        if (answer.Key === 'SC' && commentText.trim() === '') {
            return;
        }

        let sendingFeedback = feedback;
        if (sendingFeedback) {
            sendingFeedback.AnswerId = answerId;
            sendingFeedback.Comment = commentText;
        } else {
            sendingFeedback = new Feedback(0, question.Id, answerId, uid, commentText);
        }
        if (sendingFeedback) {
            Feedback.SaveFeedback(sendingFeedback)
                .subscribe( responseFeedback => {
                    if (responseFeedback) {
                        const feedbacks = conference.Feedbacks;
                        const index = feedbacks.findIndex(fb => fb.QuestionId === question.Id);
                        if (index > -1) {
                            feedbacks.splice(index, 1);
                        }
                        feedbacks.push(responseFeedback);
                        setFeedback(responseFeedback);
                        const conf = {...conference, Feedbacks: feedbacks};
                        setConference(conf);
                        // const answer = question.Answers.find(an => an.Id === responseFeedback.AnswerId);
                        // if (answer) {setAnswerText(answer.Text);}
                    }
                })
        }
    };

    const alertVariant = () => {
        if (answerKey === 'TN' || answerKey === 'F' || answerKey === 'AM') {return 'info'}
        if (answerKey === 'Y') {return 'success'}
        if (answerKey === 'N') {return 'danger'}
        if (answerKey === 'A') {return 'warning'}
        if (answerKey === 'SC') {return 'secondary'}
        return 'secondary';
    }

    const ButtonText = () => {
        if (answerKey === 'SC') {
            return 'remove comment';
        } else if (answerKey === 'AM') {
            return 'remove acceptance';
        } else {
            return commentText === "" ? "remove vote" : "remove vote and comment";
        }
    }

    const YouSelectedText = () => {
        // console.log(answerKey);
        if (answerKey === 'SC') {
            return commentText === "" ? "no comment saved" : "comment saved";
        } else {
            return 'You selected: ' + answerText;
        }
    }

    const showAnswers = () => {
        // console.log('isFinalized: ' + isFinalized);
        // console.log('isAccepted: ' + isAccepted);
        let ret = false;
        if (cState.ConferenceState === ConferenceState.Voting) {
            ret = ((feedback === undefined || feedback === null) && !isFinalized);
        } else if (cState.ConferenceState === ConferenceState.OnlineMinutes) {
            // Alte variante "Accept Minutes" nur dann anzeigen wenn "Finalized" ist
            // ret = ((feedback === undefined || feedback === null) && isFinalized && !isAccepted)
            // Neue Variante "Accept Minutes" immer anzeigen
            ret = ((feedback === undefined || feedback === null) && !isAccepted)
        }
        /*
        const ret = ((feedback === undefined || feedback === null) && !isFinalized) ||
            ((feedback === undefined || feedback === null) && isFinalized && !isAccepted);

         */
        // console.log('showAnswer: ' + ret);
        return ret;
    }

    return (
        <>
            {/* FEEDBACK Comment InputField */}
            {((((feedback === undefined || feedback === null) && question.FreeTextType !== FreeTextType.NoFreeText)) && !isFinalized) && cState.ConferenceState === ConferenceState.Voting && (
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={'col pb-3 background-text'}>
                                    <label htmlFor={'L' + question.Id}>Comment</label>
                                    <textarea id={'L' + question.Id} className={'form-control'} rows={6} maxLength={1300} value={commentText} onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>,): void => setCommentText(ev.target.value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* FEEDBACK Comment View */}
            {(feedback && question.FreeTextType !== FreeTextType.NoFreeText) && (
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={'col pb-3 background-text'}>
                                    <label htmlFor={'L' + question.Id}>Comment</label>
                                    <pre>{commentText === '' ? 'no comment' : commentText}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* FEEDBACK Comment View IF isFinalized and NO COMMENT is saved */}
            {(!feedback && question.FreeTextType === FreeTextType.OnlyFreeText && isFinalized) && (
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={'col pb-3 background-text'}>
                                    <label htmlFor={'L' + question.Id}>Comment</label>
                                    <pre>{commentText === '' ? 'no comment' : commentText}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* Question Text as Lead if a QuestionTExt exists */}
            {question.Text !== '' && (
            <div className={'row'}>
                <div className={'col'}>
                    <p className={'lead'}>{question.Text}</p>
                </div>
            </div>
            )}
            <div className={'row'}>
                {/* IF there is a FEEDBACK show AlertBox with REMOVE Button */}
                {(feedback !== undefined && feedback !== null) && (
                    <div className={'col'}>
                        <Alert variant={alertVariant()}>
                            <div className={'row'}>
                                <div className={'col-6'}>
                                    {YouSelectedText()}
                                </div>
                                <div className={'col-6'}>
                                    {/* Show REMOVE Button only if state is VOTING */}
                                    {((!isFinalized && cState.ConferenceState === ConferenceState.Voting) || (isFinalizeButton && cState.ConferenceState === ConferenceState.Voting) || (isAcceptedButton && cState.ConferenceState === ConferenceState.OnlineMinutes))  && (
                                    <Button variant={'secondary'} block size={'sm'} onClick={removeFeedback} disabled={isLoadingRemove}>
                                        {isLoadingRemove && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />&nbsp;
                                            </>
                                        )}
                                        {ButtonText()}</Button>
                                    )}
                                </div>
                            </div>
                        </Alert>
                    </div>
                )}
                {showAnswers() && (
                    <>
                    {question.Answers.map(answer => (
                            <AnswerButton key={answer.Id} answer={answer} answerCount={question.Answers.length} question={question} clickHandler={(e) => saveFeedback(e, answer.Id)}/>
                        ))}
                    </>
                )}
            </div>

            <Modal show={showCannotFinalize} onHide={handleCannotFinalizeClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cannot Finalize voting</Modal.Title>
                </Modal.Header>
                <Modal.Body>Please answer all votes before finalizing.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCannotFinalizeClose} block>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default QuestionView;
