import { createContext, useContext } from "react";

import { State } from "../model/conference/State";

export type CStateContextType = {
    cState: State;
    setCState: (cState: State) => void;
}

export const CStateContext = createContext<CStateContextType>({ cState: new State(), setCState: cState => console.warn('no CState provider')});
export const useCState = () => useContext(CStateContext);
