import React, { FC, useContext } from 'react';
import { PagePartGroup } from "../../model/conference/PagePartGroup";
import PagePartView from "./PagePartView";
import { PagePart } from "../../model/conference/PagePart";
import { ConferenceState } from "../../model/Enum/ConferenceState";
import { ConferenceContext, useConference } from "../../context/ConferenceContext";
import { useCState } from "../../context/StatusContext";
import { Conference } from "../../model/conference/Conference";

type PagePartGroupViewProps = {
    pagepartgroup: PagePartGroup;
}

const PagePartGroupView: FC<PagePartGroupViewProps> = ( { pagepartgroup}) => {

    // Context
    // const {conference, setConference} = useConference();
    const {cState} = useCState();
    const {conference} = useConference();

    const isVisible = () => {
        if (pagepartgroup.SortOrder === 100) {
            // FINALIZE PAGEPARTGROUP
            if ( cState.ConferenceState === ConferenceState.Loading ) { return false; }
            if ( cState.ConferenceState === ConferenceState.Offline ) { return false; }
            if ( cState.ConferenceState === ConferenceState.BeforeVoting ) { return false; }
            if ( cState.ConferenceState === ConferenceState.Voting ) { return true; }
            if ( cState.ConferenceState === ConferenceState.BetweenVotingAndOnlineMinutes ) { return false; }
            if ( cState.ConferenceState === ConferenceState.OnlineMinutes ) { return true; }
            if ( cState.ConferenceState === ConferenceState.AfterOnlineMinutes ) { return true; }
        } else { return true; }
    }

    const isLast = (pagepart: PagePart) => {
        // check if there is one after this
        let lIsLast = false;
        pagepartgroup.PageParts.forEach((pp) => {
            if (Conference.HasVotingRightsPagePart(conference, pp)) {
                lIsLast = pp.Id === pagepart.Id;
            }
        })
        return lIsLast;
    }

    return (
        <>
            {isVisible() && (
            <div className={`card mt-3 ${pagepartgroup.OnPrint ? '' : 'd-print-none'}`}>
                <div className={'card-body'}>
                    {pagepartgroup.SortOrder < 100 && (
                    <div className={'row'}>
                        <div className={'col'}>
                            <h3>{pagepartgroup.Title}</h3>
                        </div>
                    </div>
                    )}
                    {pagepartgroup.SortOrder < 100 && (
                    <hr className={'d-print-none'} />
                    )}
                    {/* all pageparts comes here */}
                    {pagepartgroup.PageParts.map((pagepart: PagePart, index) => (
                        <PagePartView key={pagepart.Id} pagepart={pagepart} last={isLast(pagepart)}></PagePartView>
                    ))}
                </div>
            </div>
            )}
        </>
    );
};

export default PagePartGroupView;
