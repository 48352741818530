import { FeedbackResult } from "./FeedbackResult";
import { Participant } from "./Participant";
import { Observable } from "rxjs";
import { HttpClient } from "../HttpClient/HttpClient";

export class FeedbackFullResult {

    /* Props comes here */
    public Feedbacks: Array<FeedbackResult>;
    public VotingParticipants: Array<Participant>;

    constructor() {
        this.Feedbacks = new Array<FeedbackResult>();
        this.VotingParticipants = new Array<Participant>();
    }

    public static GetFullResultList(uid: string): Observable<FeedbackFullResult> {
        let locHttpClient = new HttpClient();
        const ret = locHttpClient.GetObject<FeedbackFullResult>('conference/feedback/fullresult/' + uid);
        return ret;
    }
}
