import { createContext, useContext } from "react";
import { FeedbackResult } from "../model/conference/FeedbackResult";
import { FeedbackFullResult } from "../model/conference/FeedbackFullResult";

export type ResultContextType = {
    result: FeedbackFullResult;
    setResult: (result: FeedbackFullResult) => void;
}

export const ResultContext = createContext<ResultContextType>({ result: new FeedbackFullResult(), setResult: result => console.warn('no Result provider')});
export const useResult = () => useContext(ResultContext);
