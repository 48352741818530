import { DateTime } from "./DateTime";
import { Pad } from "./Pad";

export class TimeSpan {

    /* Props comes here */
    public days = 0;
    public hours = 0;
    public minutes = 0;
    public seconds = 0;


    constructor() {
    }

    // public static Difference1(From: DateTime, To: DateTime): TimeSpan {
    //     const retTimeSpan = new TimeSpan();
    //
    //     const FromDate = new Date(From.year, From.month - 1, From.day, From.hour, From.minute, From.second);
    //     const ToDate = new Date(To.year, To.month - 1, To.day, To.hour, To.minute, To.second);
    //
    //     // To calculate the time difference of two dates
    //     const Difference_In_Secondes = (+ToDate - +FromDate) / 1000;
    //
    //     const FullDays = Math.trunc(Difference_In_Secondes / (60 * 60 * 24));
    //     const FullDaysInSecond = FullDays * 24 * 60 * 60;
    //
    //     const FullHours = Math.trunc((Difference_In_Secondes - FullDaysInSecond) / (60 * 60));
    //     const FullHoursInSecond = FullHours * 60 * 60;
    //
    //     const FullMinutes = Math.trunc((Difference_In_Secondes - FullDaysInSecond - FullHoursInSecond) / 60);
    //     const FullMinutesInSecond = FullMinutes * 60;
    //
    //     const FullSeconds = Math.trunc(Difference_In_Secondes - FullDaysInSecond - FullHoursInSecond - FullMinutesInSecond);
    //
    //     retTimeSpan.days = FullDays;
    //     retTimeSpan.hours = FullHours;
    //     retTimeSpan.minutes = FullMinutes;
    //     retTimeSpan.seconds = FullSeconds;
    //
    //     return retTimeSpan;
    // }



    public static Difference(From: DateTime, To: DateTime): TimeSpan {
        const retTimeSpan = new TimeSpan();

        const FromDate = new Date(From.year, From.month - 1, From.day, From.hour, From.minute, From.second);
        const ToDate = new Date(To.year, To.month - 1, To.day, To.hour, To.minute, To.second);

        const Difference_In_Secondes = (+ToDate - +FromDate) / 1000;

        if (Difference_In_Secondes > 0) {
            retTimeSpan.days =  Math.floor(Difference_In_Secondes / (60 * 60 * 24));
            retTimeSpan.hours = Math.floor((Difference_In_Secondes / (60 * 60)) % 24);
            retTimeSpan.minutes = Math.floor((Difference_In_Secondes / 60) % 60);
            retTimeSpan.seconds = Math.floor((Difference_In_Secondes) % 60);
        }
        return retTimeSpan;
    }

    public static Difference1(From: Date, To: DateTime): TimeSpan {
        const retTimeSpan = new TimeSpan();

        // const FromDate = new Date(From.year, From.month - 1, From.day, From.hour, From.minute, From.second);
        const ToDate = new Date(To.year, To.month - 1, To.day, To.hour, To.minute, To.second);

        const Difference_In_Secondes = (+ToDate - +From) / 1000;

        if (Difference_In_Secondes > 0) {
            retTimeSpan.days =  Math.floor(Difference_In_Secondes / (60 * 60 * 24));
            retTimeSpan.hours = Math.floor((Difference_In_Secondes / (60 * 60)) % 24);
            retTimeSpan.minutes = Math.floor((Difference_In_Secondes / 60) % 60);
            retTimeSpan.seconds = Math.floor((Difference_In_Secondes) % 60);
        }
        return retTimeSpan;
    }


    public Format(): string {
        let TimeString = '';
        if (this.days > 0) {
            TimeString += Pad.Number(this.days, 3);
            TimeString += ':';
        }
        TimeString += Pad.Number(this.hours, 2);
        TimeString += ':';
        TimeString += Pad.Number(this.minutes, 2);
        TimeString += ':';
        TimeString += Pad.Number(this.seconds, 2);
        return TimeString;
    }
}
