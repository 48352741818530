import { BaseObject } from "../BaseObject/BaseObject";
import { ConferenceRight } from "../Enum/ConferenceRight";

export class Participant extends BaseObject {

    /* Props comes here */
    public Key: string;
    public Name: string;
    public Right: ConferenceRight;
    public isOnline: boolean;

    constructor() {
        super(0);
        this.Key = '';
        this.Name = '';
        this.Right = ConferenceRight.User;
        this.isOnline = false;
    }
}
