export class FeedbackDetail {

    /* Props comes here */
    public ParticipantKey: string;
    public AnswerKey: string;
    public Comment: string;

    constructor(PKey: string, AKey: string, Comment: string) {
        this.ParticipantKey = PKey;
        this.AnswerKey = AKey;
        this.Comment = Comment;
    }
}
