export class Pad {
    public static Number(value: number, size?: number): string {
        if (size === undefined) { size = 2; }
        if (value.toString().length >= size) {
            return value.toString();
        } else {
            let s = value + '';
            while (s.length < size) { s = '0' + s; }
            return s;
        }
    }
}
