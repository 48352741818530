import React, { FC, useEffect, useState } from "react";
import { ConferenceState } from "../../model/Enum/ConferenceState";
import { Conference } from "../../model/conference/Conference";
import { DateTime } from "../../model/DateTime/DateTime";
import { useConference } from "../../context/ConferenceContext";
import { useCState } from "../../context/StatusContext";
import { TimeSpan } from "../../model/DateTime/TimeSpan";
import { State } from "../../model/conference/State";
import { count } from "rxjs/operators";

const StatusView: FC = () => {
    const {conference} = useConference();
    const {cState} = useCState();

    const calculateTimeLeft = (tim: Date) => {
        let referenceTime = new DateTime();
        if (cState.ConferenceState === ConferenceState.Voting) { referenceTime = new DateTime(cState.VotingTo)};
        if (cState.ConferenceState === ConferenceState.OnlineMinutes) { referenceTime = new DateTime(cState.MinutesTo)};
        if (cState.ConferenceState === ConferenceState.BetweenVotingAndOnlineMinutes) { referenceTime = new DateTime(cState.MinutesFrom)};
        if (cState.ConferenceState === ConferenceState.BeforeVoting) { referenceTime = new DateTime(cState.VotingFrom)};
        return TimeSpan.Difference1(tim, referenceTime);
    }

    const [timeSpan, setTimeSpan] = useState(calculateTimeLeft(new DateTime(cState.ServerTime).toDate()));

    const calculateCounter = (cTime: Date) => {
        cTime.setSeconds( cTime.getSeconds() + 1);
        setTimeSpan(calculateTimeLeft(cTime));
    }

    useEffect(() => {
        let insideTime = new DateTime(cState.ServerTime).toDate();
        const interval = setInterval(() => {
            calculateCounter(insideTime);
        }, 960);
        return () => clearInterval(interval);
    }, [cState]);

    const DetailedStatus = () => {
        // console.log('statusbar refreshed');
        if (cState.ConferenceState === ConferenceState.Offline) { return 'Conference OFFLINE'; }
        if (cState.ConferenceState === ConferenceState.BeforeVoting) { return 'Voting starting in ' + timeSpan.Format();}
        if (cState.ConferenceState === ConferenceState.Voting) { return 'Voting active for ' + timeSpan.Format();}
        if (cState.ConferenceState === ConferenceState.BetweenVotingAndOnlineMinutes) { return 'Voting closed - Online Minutes starting in ' + + timeSpan.Format();}
        if (cState.ConferenceState === ConferenceState.OnlineMinutes) { return 'Online Minutes active for ' + timeSpan.Format();}
        if (cState.ConferenceState === ConferenceState.AfterOnlineMinutes) { return 'Conference CLOSED';}
    }

    return (
        <div id={'status'} className={'fixed-status d-print-none'}>
            {conference.Participant && conference.Participant.Key !== '' && (
                <span>Participant {conference.Participant.Key} - {DetailedStatus()}</span>
            )}
        </div>
    );
}

export default StatusView;
