import React, { FC, useContext, useState } from "react";
import { Answer } from "../../model/conference/Answer";
import { Button, Spinner } from "react-bootstrap";
import { Question } from "../../model/conference/Question";
import { useConference } from "../../context/ConferenceContext";

type AnswerButtonProps = {
    answer: Answer;
    answerCount: number;
    question: Question;
    clickHandler: (e:  React.MouseEvent<HTMLButtonElement>) => void;
}

const AnswerButton: FC<AnswerButtonProps> = ({ answer, answerCount, question, clickHandler }) => {

    // Context
    const {conference, setConference} = useConference();

    // State
    const [isLoadingSave, setIsLoadingSave] = useState(false);

    const v = () => {
        if (answer) {
            if (answer.Key === 'TN' || answer.Key === 'F' || answer.Key === 'AM') {return 'info'}
            if (answer.Key === 'Y') {return 'success'}
            if (answer.Key === 'N') {return 'danger'}
            if (answer.Key === 'A') {return 'warning'}
            if (answer.Key === 'SC') {return 'secondary'}
        }
        return 'info';
    }

    const c = () => {
        if (answerCount === 2) { return 'col-md-6 mb-1'; }
        if (answerCount === 3) { return 'col-md-4 mb-1'; }
        if (answerCount === 4) { return 'col-md-3 mb-1'; }
        return 'col mb-1';
    }
    const saveFeedback = (e:  React.MouseEvent<HTMLButtonElement>) => {
        // setIsLoadingSave(true);
        clickHandler(e);
    }

    return (
        <div className={c()}>
            <Button variant={v()} block onClick={saveFeedback} disabled={isLoadingSave}>
                {isLoadingSave && (
                <>
                    <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />&nbsp;
                </>
                )}
                {answer.Text}</Button>
        </div>
    );
}

export default AnswerButton;
