import { BaseObject } from "../BaseObject/BaseObject";
import { ConferenceState } from "../Enum/ConferenceState";
import { Observable } from "rxjs";
import { HttpClient } from "../HttpClient/HttpClient";

export class State extends BaseObject {

    /* Props comes here */
    public ConferenceState: ConferenceState;
    public ServerTime: string;
    public BeginDate: string;
    public EndDate: string;
    public VotingFrom: string;
    public VotingTo: string;
    public MinutesFrom: string;
    public MinutesTo: string;

    constructor() {
        super(0);
        this.ConferenceState = ConferenceState.Loading;
        this.ServerTime = '';
        this.BeginDate = '';
        this.EndDate = '';
        this.VotingFrom = '';
        this.VotingTo = '';
        this.MinutesFrom = '';
        this.MinutesTo = '';
    }

    static GetForParticipantUid(uid: string): Observable<State> {
        let locHttpClient = new HttpClient();
        const ret = locHttpClient.GetObject<State>('conference/state/' + uid);
        return ret;
    }
}
