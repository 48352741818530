import React, { FC, useEffect, useState } from "react";
import { useConference } from "../../context/ConferenceContext";
import { useResult } from "../../context/ResultContext";
import { FeedbackFullResult } from "../../model/conference/FeedbackFullResult";
import { useUid } from "../../context/UidContext";
import { FeedbackDetail } from "../../model/conference/FeedbackDetail";
import { Participant } from "../../model/conference/Participant";
import { Question } from "../../model/conference/Question";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const ConferenceResultView: FC = () => {

    // Context
    const {uid, setUid} = useUid();

    // State
    const {conference} = useConference();
    const {result, setResult} = useResult();

    // State
    const [questionList, setQuestionList] = useState(new Array<Question>());

    const fetchResults = () => {
        FeedbackFullResult.GetFullResultList(uid)
            .subscribe(responseResult => {
                if (responseResult) {
                    responseResult.Feedbacks.sort((one, two) => (one.ParticipantKey > two.ParticipantKey ? 1 : -1));
                    responseResult.VotingParticipants.sort((one, two) => (one.Key > two.Key ? 1 : -1));
                    setResult(responseResult);
                }
            })
    }

    const FBDetail = (question: Question) => {
        let lRes = result.Feedbacks.filter(res => res.QuestionId === question.Id);
        lRes.sort((one, two) => (one.ParticipantKey > two.ParticipantKey ? 1 : -1));
        let lPar = result.VotingParticipants;
        lPar.sort((one, two) => (one.Key > two.Key ? 1 : -1));

        let lResultList = new Array<FeedbackDetail>();
        lPar.forEach((part) => {
            const ans = lRes.find(fb => fb.ParticipantKey === part.Key);
            if (ans) {
                lResultList.push(new FeedbackDetail(part.Key, ans.AnswerKey, ans.Comment));
            } else {
                lResultList.push(new FeedbackDetail(part.Key, '', ''))
            }
        });
        return lResultList;
    }

    const pBackground = (isonline: boolean) => {
        return isonline ? "text-center table-success" : "text-center";
    }

    const tcBackground = (key: string) => {
        if (key === "TN") { return "text-center table-info"; }
        if (key === "F") { return "text-center table-info"; }
        if (key === "AM") { return "text-center table-info"; }
        if (key === "Y") { return "text-center table-success"; }
        if (key === "N") { return "text-center table-danger"; }
        if (key === "A") { return "text-center table-warning"; }
        if (key !== "") { return "text-center table-info"; }
        else { return "text-center"; };
    }

    const resultIcon = (key: string) => {
        if (key === "TN"){ return <i className={'bi bi-check2 lead'}></i> }
        if (key === "F"){ return <i className={'bi bi-check2 lead'}></i> }
        if (key === "AM"){ return <i className={'bi bi-check2 lead'}></i> }
        if (key === "Y") { return <i className={'bi bi-check2 lead'}></i> }
        if (key === "N") { return <i className={'bi bi-x lead'}></i> }
        if (key === "A") { return <i className={'bi bi-circle lead'}></i> }
        if (key === "SC") { return }
        if (key !== "") { return <span>{key}</span> }
        else { return <i className={'bi bi-dash lead'}></i> }
    }

    const popover = (comment: string) => {
        return (
            <Popover id="popover-basic">
                <Popover.Title as="h3">Comment</Popover.Title>
                <Popover.Content>
                    <pre>{comment}</pre>
                </Popover.Content>
            </Popover>
        );
    }

    const showComment = (fbd: FeedbackDetail) => {
        if (fbd.Comment !== '') {
            return (
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={popover(fbd.Comment)}>
                <span className={fbd.AnswerKey === 'SC' ? '' : 'ml-3'}><i className={'bi bi-chat-dots'}></i></span>
                </OverlayTrigger>
            )
        }
    }

    //Onload initial load of Conference run only once
    useEffect(() => {
        const interval = setInterval(() => {
            fetchResults();
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        fetchResults();
        // get All Question from Conference in right order top to down
        const lQuestions = new Array<Question>();
        conference.PagePartGroups.forEach( (ppg )=> {
            ppg.PageParts.forEach((pp) => {
                if (pp.Question) {
                    lQuestions.push(pp.Question);
                }
            })
        });
        setQuestionList(lQuestions);
    },[]);

    return (
        <div className={'mt-4'}>
            <h2>Livedata</h2>
            <table className={'table'}>
                <thead>
                <tr>
                    <th>Q</th>
                    {result.VotingParticipants.map((ParticipantItem: Participant) => (
                        <th scope={'col'} key={'h_' + ParticipantItem.Key} className={pBackground(ParticipantItem.isOnline)}><div>{ParticipantItem.Key}</div><small>{ParticipantItem.isOnline ? "online" : "offline"}</small></th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {questionList.map((QuestionItem: Question) => (
                    <tr key={'q_' + QuestionItem.Key}>
                        <th scope={'row'} >{QuestionItem.Key}</th>
                        {FBDetail(QuestionItem).map((resultItem: FeedbackDetail) => (
                            <td key={'h_' + resultItem.ParticipantKey} className={tcBackground(resultItem.AnswerKey)}>{resultIcon(resultItem.AnswerKey)}{showComment(resultItem)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default ConferenceResultView;
