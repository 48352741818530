import React, { createContext, useContext } from "react";
import { Conference } from "../model/conference/Conference";

export type ConferenceContextType = {
    conference: Conference;
    setConference: (Conference: Conference) => void;
}

export const ConferenceContext = createContext<ConferenceContextType>({ conference: new Conference(), setConference: conference => console.warn('no conference provider')});
export const useConference = () => useContext(ConferenceContext);
