import React, { FC, useContext, useEffect, useState } from 'react';
import { PagePart } from "../../model/conference/PagePart";
import { Button, Collapse } from "react-bootstrap";
import QuestionView from './QuestionView';
import { ExpansionType } from "../../model/Enum/ExpansionType";
import { ConferenceState } from "../../model/Enum/ConferenceState";
import { useConference } from "../../context/ConferenceContext";
import { useCState } from "../../context/StatusContext";
import QuestionResultView from "./QuestionResultView";
import { Conference } from "../../model/conference/Conference";

type PagePartViewProps = {
    pagepart: PagePart;
    last: boolean;
}

const PagePartView: FC<PagePartViewProps> = ( { pagepart , last}) => {

    // Context
    const {conference, setConference} = useConference();
    const {cState, setCState} = useCState();

    // State
    const [openExplanation, setOpenExplanantion] = useState(false);
    const [openPresentation, setOpenPresentation] = useState(false);
    const [openBackground, setOpenBackground] = useState(false);
    const [openDownloads, setOpenDownloads] = useState(false);

    const visiblePagePart = () => {
        if (cState.ConferenceState == ConferenceState.Voting) {
            if (pagepart.SortOrder === 1000) { return false; }
        }
        if (cState.ConferenceState == ConferenceState.BetweenVotingAndOnlineMinutes) {
            if (pagepart.SortOrder === 999) { return false; }
            if (pagepart.SortOrder === 1000) { return false; }
        }
        if (cState.ConferenceState == ConferenceState.OnlineMinutes) {
            // if (pagepart.SortOrder === 999) { return false; }
        }

        return Conference.HasVotingRightsPagePart(conference, pagepart);
        // return true;
    }

    const visibleQuestion = () => {
        if (cState.ConferenceState == ConferenceState.Offline) {return false;}
        if (cState.ConferenceState == ConferenceState.BeforeVoting) {return false;}
        if (cState.ConferenceState == ConferenceState.Voting) {
            // alles true ausser onlöine minutes
            return true;
        }
        if (cState.ConferenceState == ConferenceState.BetweenVotingAndOnlineMinutes) {return true;}
        if (cState.ConferenceState == ConferenceState.OnlineMinutes) {
            // Alles false ausser online minutes
            if (pagepart.SortOrder === 1000) {
                return true;
            } else {
                return false;
            }
        }
        if (cState.ConferenceState == ConferenceState.AfterOnlineMinutes) {return false;}
    }

    const visibleQuestionResult = () => {
        if (cState.ConferenceState == ConferenceState.OnlineMinutes) {
            if (pagepart.SortOrder !== 1000) {
                return true;
            } else {
                return false;
            }
        } else if (cState.ConferenceState == ConferenceState.AfterOnlineMinutes) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
        if (pagepart.ExplanationExpansionType === ExpansionType.NotCollapsable || pagepart.ExplanationExpansionType === ExpansionType.CollapsableOpened) {
            setOpenExplanantion(!openExplanation);
        }
        if (pagepart.PresentationExpansionType === ExpansionType.NotCollapsable || pagepart.PresentationExpansionType === ExpansionType.CollapsableOpened) {
            setOpenPresentation(!openPresentation);
        }
        if (pagepart.BackgroundExpansionType === ExpansionType.NotCollapsable || pagepart.BackgroundExpansionType === ExpansionType.CollapsableOpened) {
            setOpenBackground(!openBackground);
        }
        if (pagepart.DownloadsExpansionType === ExpansionType.NotCollapsable || pagepart.DownloadsExpansionType === ExpansionType.CollapsableOpened) {
            setOpenDownloads(!openDownloads);
        }
    }, [])

    const headerTagClass = () => {
        const term = pagepart.SpecialTag.toUpperCase().trim();
        const addMt = (pagepart.Text === '') ? ' mt-2' : '';
        if (term.length > 0) {
            switch (term) {
                case "HEADER1": {
                    return 'h4' + addMt;
                }
                case "HEADER2" : {
                    return 'h5' + addMt;
                }
                case "HEADER3" : {
                    return 'h6' + addMt;
                }
            }
        }
        return 'h4' + addMt;
    }

    const showFinalHR = () => {
        const term = pagepart.SpecialTag.toUpperCase().trim();
        if (term.length > 0) {
            if (term.startsWith('HEADER')) {
                if (pagepart.Text === '') {
                    if (pagepart.Question && pagepart.Question.Text !== '') {
                        return true;
                    }
                    return false;
                }
            }
        }
        return true;
    }

    function createMarkup(markup: string) {
        return {__html: markup};
    }

    return (
        <>
            {(visiblePagePart()) && (
            <div className={pagepart.OnPrint ? '':'d-print-none'}>
                <div className={'row'}>
                    <div className={'col'}>
                        <div className={headerTagClass()}>{pagepart.Title}</div>

                        <ul className={'list-inline mt-3'}>
                            {pagepart.Explanation !== '' && pagepart.ExplanationExpansionType !== ExpansionType.NotCollapsable &&
                            <li className={'list-inline-item'}>
                                <Button
                                    onClick={() => setOpenExplanantion(!openExplanation)}
                                    aria-controls={'part_E_Q_' + pagepart.Id}
                                    aria-expanded={openExplanation}
                                    variant={'outline-primary'}
                                    size={'sm'}
                                >
                                    { openExplanation ? 'Hide Explanation' : 'Show Explanation' }
                                </Button>

                            </li>
                            }
                            {pagepart.Presentation !== '' && pagepart.PresentationExpansionType !== ExpansionType.NotCollapsable &&
                            <li className={'list-inline-item'}>
                                <Button
                                    onClick={() => setOpenPresentation(!openPresentation)}
                                    aria-controls={'part_P_Q_' + pagepart.Id}
                                    aria-expanded={openPresentation}
                                    variant={'outline-primary'}
                                    size={'sm'}
                                >
                                    { openPresentation ? 'Hide Presentation' : 'Show Presentation' }
                                </Button>

                            </li>
                            }
                            {pagepart.Background !== '' && pagepart.BackgroundExpansionType !== ExpansionType.NotCollapsable &&
                            <li className={'list-inline-item'}>
                                <Button
                                    onClick={() => setOpenBackground(!openBackground)}
                                    aria-controls={'part_B_Q_' + pagepart.Id}
                                    aria-expanded={openBackground}
                                    variant={'outline-primary'}
                                    size={'sm'}
                                >
                                    { openBackground ? 'Hide Background Information' : 'Show Background Information' }
                                </Button>

                            </li>
                            }
                            {pagepart.Downloads !== '' && pagepart.DownloadsExpansionType !== ExpansionType.NotCollapsable &&
                            <li className={'list-inline-item'}>
                                <Button
                                    onClick={() => setOpenDownloads(!openDownloads)}
                                    aria-controls={'part_D_Q_' + pagepart.Id}
                                    aria-expanded={openDownloads}
                                    variant={'outline-primary'}
                                    size={'sm'}
                                >
                                    { openDownloads ? 'Hide Downloads' : 'Show Downloads' }
                                </Button>

                            </li>
                            }
                        </ul>

                        {pagepart.Explanation !== '' && (
                            <Collapse in={openExplanation}>
                                <div id={'part_E_Q_' + pagepart.Id} className="collapse background-text" role="tabpanel" aria-labelledby={'heading_E_Q_' + pagepart.Id}>
                                    <div dangerouslySetInnerHTML={createMarkup(pagepart.Explanation)}></div>
                                </div>
                            </Collapse>
                        )}

                        {pagepart.Presentation !== '' && (
                            <Collapse in={openPresentation}>
                                <div id={'part_P_Q_' + pagepart.Id} className="collapse background-text" role="tabpanel" aria-labelledby={'heading_P_Q_' + pagepart.Id}>
                                    <div dangerouslySetInnerHTML={createMarkup(pagepart.Presentation)}></div>
                                </div>
                            </Collapse>
                        )}

                        {pagepart.Background !== '' && (
                            <Collapse in={openBackground}>
                                <div id={'part_B_Q_' + pagepart.Id} className="collapse background-text" role="tabpanel" aria-labelledby={'heading_B_Q_' + pagepart.Id}>
                                    <div dangerouslySetInnerHTML={createMarkup(pagepart.Background)}></div>
                                </div>
                            </Collapse>
                        )}

                        {pagepart.Downloads !== '' && (
                            <Collapse in={openDownloads}>
                                <div id={'part_D_Q_' + pagepart.Id} className="collapse background-text" role="tabpanel" aria-labelledby={'heading_D_Q_' + pagepart.Id}>
                                    <div className={'row'}>
                                        <div className={'col-sm-4'}>
                                            <p>Available Downloads:</p>
                                        </div>
                                        <div className={'col-sm-8'} dangerouslySetInnerHTML={createMarkup(pagepart.Downloads)}></div>
                                    </div>
                                </div>
                            </Collapse>
                        )}

                        {pagepart.Text !== '' &&
                            <div className={'mb-3'} dangerouslySetInnerHTML={createMarkup(pagepart.Text)}></div>
                        }

                    </div>
                </div>

                {pagepart.Question && visibleQuestion() && (
                    <QuestionView question={pagepart.Question}></QuestionView>
                )}

                {pagepart.Question && visibleQuestionResult() && (
                    <QuestionResultView key={pagepart.Question.Id} question={pagepart.Question}></QuestionResultView>
                )}

                {(pagepart.SortOrder < 999 && !last && showFinalHR()) && (
                    <hr className={'d-print-none'} />
                )}
            </div>
            )}
        </>
    );
}

export default PagePartView;
