import React, { FC, useEffect, useState } from "react";
import { useResult } from "../../context/ResultContext";
import { FeedbackResult } from "../../model/conference/FeedbackResult";
import { Question } from "../../model/conference/Question";
import { FreeTextType } from "../../model/Enum/FreeTextType";
import { Participant } from "../../model/conference/Participant";
import { FeedbackDetail } from "../../model/conference/FeedbackDetail";

type QuestionResultViewProps = {
    question: Question;
}

const QuestionResultView: FC<QuestionResultViewProps> = ({question}) => {

    // Context
    const {result, setResult} = useResult();

    // State
    const [questionResult, setQuestionResult] = useState(new Array<FeedbackResult>());
    const [VotingParticipants, setVotingParticipants] = useState(new Array<Participant>());
    const [FBDetail, setFBDetail] = useState(new Array<FeedbackDetail>());

    const tcBackground = (key: string) => {
        if (key === "TN") { return "text-center table-info"; }
        if (key === "F") { return "text-center table-info"; }
        if (key === "AM") { return "text-center table-info"; }
        if (key === "Y") { return "text-center table-success"; }
        if (key === "N") { return "text-center table-danger"; }
        if (key === "A") { return "text-center table-warning"; }
        if (key !== "") { return "text-center table-info"; }
        else { return "text-center"; };
    }

    const resultIcon = (key: string) => {
        if (key === "TN"){ return <i className={'bi bi-check2 lead'}></i>; }
        if (key === "F"){ return <i className={'bi bi-check2 lead'}></i>; }
        if (key === "AM"){ return <i className={'bi bi-check2 lead'}></i>; }
        if (key === "Y") { return <i className={'bi bi-check2 lead'}></i>; }
        if (key === "N") { return <i className={'bi bi-x lead'}></i>; }
        if (key === "A") { return <i className={'bi bi-circle lead'}></i>; }
        if (key !== "") { return <span>{key}</span> }
        else { return <i className={'bi bi-dash lead'}></i>; }
    }

    const hasComment = () => {
        const foundone = questionResult.find(res => res.Comment !== "");
        return foundone ? true : false;
    }

    // onload
    useEffect(() => {
        let lRes = result.Feedbacks.filter(res => res.QuestionId === question.Id);
        lRes.sort((one, two) => (one.ParticipantKey > two.ParticipantKey ? 1 : -1));
        let lPar = result.VotingParticipants;
        lPar.sort((one, two) => (one.Key > two.Key ? 1 : -1));
        setQuestionResult(lRes);
        setVotingParticipants(lPar);

        let lResultList = new Array<FeedbackDetail>();
        lPar.forEach((part) => {
            const ans = lRes.find(fb => fb.ParticipantKey === part.Key);
            if (ans) {
                lResultList.push(new FeedbackDetail(part.Key, ans.AnswerKey, ans.Comment));
            } else {
                lResultList.push(new FeedbackDetail(part.Key, '', ''))
            }
        });
        lResultList.sort((one, two) => (one.ParticipantKey > two.ParticipantKey ? 1 : -1))
        setFBDetail(lResultList);
    },[result]);

    return (
        <>
            {/* Show all comments for this Question*/}
            {hasComment() && (
                <div className={'container'}>
                    <div className={'row background-text mt-4'}>
                        <div className={'col'}>
                            {questionResult.map((resultItem: FeedbackResult) => {
                                return resultItem.Comment !== "" ?
                                <div key={'c_' + resultItem.ParticipantKey}>
                                    <p><strong>Comment by {resultItem.ParticipantKey}</strong></p>
                                    <pre>{resultItem.Comment}</pre>
                                </div>
                                :
                                <div key={'c_' + resultItem.ParticipantKey}></div>
                            })}
                        </div>
                    </div>
                </div>
            )}
            {/* Show a table with all Participants */}
            {question.FreeTextType !== FreeTextType.OnlyFreeText && (
            <div className={'row'}>
                <div className={'col'}>
                    <table className={'table table-striped'}>
                        <thead>
                            <tr>
                                {FBDetail.map((resultItem: FeedbackDetail) => (
                                  <td key={'h_' + resultItem.ParticipantKey} className={'text-center'}>{resultItem.ParticipantKey}</td>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {FBDetail.map((resultItem: FeedbackDetail) => (
                                    <td key={'h_' + resultItem.ParticipantKey} className={tcBackground(resultItem.AnswerKey)}>{resultIcon(resultItem.AnswerKey)}</td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            )}
        </>
    );
}

export default QuestionResultView;
