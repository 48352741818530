import React, { useEffect } from 'react';
import './App.scss';
import useQueryParam from "./model/useQueryParam";
import ConferencePageView from "./components/ConferencePageView/ConferencePageView";
import { UidContext } from './context/UidContext';

function App() {
  // Read Querystring
  const [uid, setUid] = useQueryParam('uid', '');

  return (
      <UidContext.Provider value={{uid, setUid}}>
        <div className="App">
          <ConferencePageView refreshTime={10}></ConferencePageView>
        </div>
      </UidContext.Provider>
  );
}

export default App;
