import React, { FC, useEffect, useState } from "react";
import { Conference } from "../../model/conference/Conference";
import StatusView from "./StatusView";
import PagePartGroupView from "./PagePartGroupView";
import { ConferenceState } from "../../model/Enum/ConferenceState";
import { State } from "../../model/conference/State";
import { ConferenceContext } from "../../context/ConferenceContext";
import { CStateContext } from "../../context/StatusContext";
import { useUid } from "../../context/UidContext";
import { ResultContext } from "../../context/ResultContext";
import { ConferenceRight } from "../../model/Enum/ConferenceRight";
import { FeedbackFullResult } from "../../model/conference/FeedbackFullResult";
import ConferenceResultView from "./ConferenceResultView";
import { DateTime } from "../../model/DateTime/DateTime";
import { TimeSpan } from "../../model/DateTime/TimeSpan";

type ConferencePageViewProps = {
    refreshTime: number;
}

const ConferencePageView: FC<ConferencePageViewProps> = ( { refreshTime}) => {

    // Context
    const {uid, setUid} = useUid();

    // State
    const [conference, setConference] = useState<Conference>(new Conference());
    const [cState, setCState] = useState<State>(new State());
    const [result, setResult] = useState<FeedbackFullResult>(new FeedbackFullResult());
    const [isValid, setIsValid] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshInterval, setRefreshInterval] = useState(refreshTime * 1000 || 0);

    const fetchResults = () => {
        FeedbackFullResult.GetFullResultList(uid)
            .subscribe(responseResult => {
                if (responseResult) {
                    responseResult.Feedbacks.sort((one, two) => (one.ParticipantKey > two.ParticipantKey ? 1 : -1));
                    responseResult.VotingParticipants.sort((one, two) => (one.Key > two.Key ? 1 : -1));
                    setResult(responseResult);
                }
            })
    }

    // Fetch Status from API
    const fetchStatus = () => {
        if (uid !== '') {
            State.GetForParticipantUid(uid)
                .subscribe(responseState => {
                    if (responseState) {
                        setCState(responseState);
                        if (responseState.ConferenceState === ConferenceState.OnlineMinutes ||
                            responseState.ConferenceState === ConferenceState.AfterOnlineMinutes) {
                            // Load Results
                            fetchResults();
                        }
                    }
                });
        }
    }

    // Fetch Conference from API
    const fetchConference = () => {
        if (uid !== '') {
            Conference.GetForParticipantUid(uid)
                .subscribe(resp => {
                    setIsLoading(false);
                    if (resp) {
                        setConference(resp);

                        const lState = new State();
                        lState.ConferenceState = resp.ConferenceState;
                        lState.BeginDate = resp.BeginDate;
                        lState.EndDate = resp.EndDate;
                        lState.VotingFrom = resp.VotingFrom;
                        lState.VotingTo = resp.VotingTo;
                        lState.MinutesFrom = resp.MinutesFrom;
                        lState.MinutesTo = resp.MinutesTo;
                        lState.ServerTime = resp.ServerTime;
                        setCState(lState);

                        setIsValid( true );

                        setIsAdmin(resp.Participant.Right === ConferenceRight.Admin);

                        document.title = resp.Title
                        if (resp.ConferenceState === ConferenceState.OnlineMinutes ||
                            resp.ConferenceState === ConferenceState.AfterOnlineMinutes) {
                            // Load Results
                            fetchResults();
                        }
                    } else {
                        setIsValid( false );
                    }
                });
        }
    }

    // Detect change of UID
    useEffect(() => {
        if (uid === '') {
            setIsLoading(false);
            setIsValid( false );
        }
    }, [uid])

    // Onload initial load of Conference run only once
    useEffect(() => {
        fetchConference();
    }, [])

    // Detect change of CState - Periodically load CState from API
    useEffect(() => {
        const interval = setInterval(() => {
            fetchStatus();
        }, refreshInterval);
        return () => clearInterval(interval);
    }, [cState])



    return (
        <ConferenceContext.Provider value={{conference, setConference}}>
            <CStateContext.Provider value={{cState, setCState}}>
                <ResultContext.Provider value={{result, setResult}}>
                    <>
                        {!isAdmin && (
                            <>
                            {isValid && (
                            <div className={'mb-5'}>
                                <StatusView></StatusView>
                                <div className={'container main'}>
                                    <div className={'row mb-2'}>
                                        <div className={'col'}>
                                            <h1 className={'text-center'}>{conference.Title}</h1>
                                            {conference.SubTitle !== '' && (
                                                <h2 className={'text-center'}>{conference.SubTitle}</h2>
                                            )}
                                        </div>
                                    </div>

                                    {conference.ListOfParticipantsUrl !== '' && (
                                    <div className={'row d-print-none'}>
                                        <div className={'col'}>
                                            <a href={conference.ListOfParticipantsUrl} target="_blank">
                                                <h4 className={'text-center'}>List of Participants</h4>
                                            </a>
                                        </div>
                                    </div>
                                    )}

                                    {(cState.ConferenceState === ConferenceState.Offline ) && (
                                        <div className={'row mt-5 d-print-none'}>
                                            <div className={'col'}>
                                                <h3 className={'text-center'}>Conference is currently</h3>
                                                <h3 className={'text-center'}>OFFLINE</h3>
                                            </div>
                                        </div>
                                    )}

                                    {(cState.ConferenceState === ConferenceState.BeforeVoting ||
                                        cState.ConferenceState === ConferenceState.Voting ||
                                        cState.ConferenceState === ConferenceState.BetweenVotingAndOnlineMinutes ||
                                        cState.ConferenceState === ConferenceState.OnlineMinutes ||
                                        cState.ConferenceState === ConferenceState.AfterOnlineMinutes ) && (
                                        <div className={'mt-5'}>
                                        {conference.PagePartGroups.map(group => (
                                            <PagePartGroupView key={group.Id} pagepartgroup={group}></PagePartGroupView>
                                        ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                            )}

                            {(!isValid && !isLoading) && (
                            <div className={'mb-5'}>
                                <StatusView></StatusView>
                                <div className={'container main'}>
                                    <div className={'row'}>
                                        <div className={'col text-center'}>
                                            <h1>Invalid conference URL</h1>
                                            <p>Please contact us via the LIVESUPPORT.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}

                            {isLoading && (
                            <div className={'mb-5'}>
                                <div className={'container main'}>
                                    <div className={'row'}>
                                        <div className={'col text-center'}>
                                            <h1>loading conference</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )}
                            </>
                        )}
                        {isAdmin && isValid && (
                            <div className={'mb-5'}>
                                <StatusView></StatusView>
                                <div className={'container main'}>
                                    <div className={'row mb-2'}>
                                        <div className={'col'}>
                                            <h1 className={'text-center'}>{conference.Title}</h1>
                                            {conference.SubTitle !== '' && (
                                                <h2 className={'text-center'}>{conference.SubTitle}</h2>
                                            )}
                                        </div>
                                    </div>

                                    {conference.ListOfParticipantsUrl !== '' && (
                                        <div className={'row d-print-none'}>
                                            <div className={'col'}>
                                                <a href={conference.ListOfParticipantsUrl} target="_blank">
                                                    <h4 className={'text-center'}>List of Participants</h4>
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                    <ConferenceResultView></ConferenceResultView>
                                </div>
                            </div>
                        )}
                    </>
                </ResultContext.Provider>
            </CStateContext.Provider>
        </ConferenceContext.Provider>
    );
};

export default ConferencePageView;
