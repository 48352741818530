import { Observable } from "rxjs";
import { HttpClient } from "../HttpClient/HttpClient";
import { BaseObject } from "../BaseObject/BaseObject";

export class Feedback extends BaseObject {

    /* Props comes here */
    public QuestionId: number;
    public AnswerId: number;
    public UserReference: string;
    public Comment: string;

    constructor(id: number, questionid: number, answerid: number, userreference: string, comment: string) {
        super(id);
        this.QuestionId = questionid;
        this.AnswerId = answerid;
        this.UserReference = userreference;
        this.Comment = comment;
    }

    static SaveFeedback(Feedback: Feedback): Observable<Feedback> {
        let locHttpClient = new HttpClient();
        const ret = locHttpClient.Post<Feedback>('conference/feedback/save', Feedback);
        return ret;
    }

    static  RemoveFeedback(Feedback: Feedback): Observable<boolean> {
        let locHttpClient = new HttpClient();
        const ret = locHttpClient.Post<boolean>('conference/feedback/remove', Feedback);
        return ret;
    }
}
